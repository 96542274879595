import React from "react";
import styles from "styles/styles.json";
import {
  bonatraFeatures,
  featuresData,
  BonatraFeatures,
} from "@/pages/api/featuresData";
import Section from "@/lib/Layout/Wrappers/Section";
import { FeaturesCard } from "./FeaturesCard";
import {
  ringX1Data,
  ringX1Features,
  RingX1Features,
} from "@/pages/api/ringFeaturesData";
import { RingFeaturesCard } from "./RingFeaturesCard";

const Features = ({
  featuresData,
  isPageRing,
}: {
  featuresData: featuresData;
  isPageRing?: boolean;
}) => {
  return (
    <section
      className={`${styles.flexCol} max-w-7xl w-full mx-auto my-8 gap-20`}
    >
      <div className={`${styles.flexCenter} flex-col`}>
        <h3 className="text-center text-6xl font-semibold">
          {featuresData ? featuresData?.heading : ringX1Data?.heading}
        </h3>
        <Section>
          <div className="grid md:grid-rows-2 md:grid-flow-col">
            {!isPageRing
              ? bonatraFeatures?.map(
                  (
                    {
                      id,
                      title,
                      description1,
                      description2,
                      description3,
                    }: BonatraFeatures,
                    index
                  ) => {
                    return (
                      <FeaturesCard
                        id={id}
                        title={title}
                        description1={description1}
                        description2={description2}
                        description3={description3}
                        key={index}
                      />
                    );
                  }
                )
              : ringX1Features?.map(
                  ({ id, title, description }: RingX1Features, index) => {
                    return (
                      <RingFeaturesCard
                        id={id}
                        title={title}
                        description1={description}
                        key={index}
                      />
                    );
                  }
                )}
          </div>
        </Section>
      </div>
    </section>
  );
};

export default Features;
