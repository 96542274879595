import type { NextApiRequest, NextApiResponse } from 'next'

export type featuresData = {
    data: features;
    heading:string;
}

export type RingX1Features = {
  id: any | number | bigint;
  title:
    | string
    | number
    | boolean
    | React.ReactElement<
        any,
        string | React.JSXElementConstructor<any>
      >
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
  description:
    | string
    | number
    | boolean
    | React.ReactElement<
        any,
        string | React.JSXElementConstructor<any>
      >
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
}

export type features = typeof ringX1Features;

export const ringX1Features = [
    {
      id: 1,
      title: "HRV & Heart Rate",
      description:
        "The Bonatra Ring X1 can track heart rate and heart rate variability, providing insights into overall cardiovascular health and the body's ability to respond to stress. This can help users take action to improve their health and well-being.",
    },
    {
      id: 2,
      title: "Sleep",
      description:
        "With the new advanced sleep algorithm, The Bonatra Ring X1 can accurately evaluate the quality of your sleep based on the status and duration of each sleep stage, such as light sleep, deep sleep, wake-up time and REM during sleep.",
    },
    {
      id: 3,
      title: "SPO2",
      description:
        "The Bonatra Ring X1 can detect real-time results of SpO2, allowing users to get to know about their oximetry level anytime when they need it.",
    },
    {
      id: 4,
      title: "Temperature",
      description: "Finger temperature can be measured by the Bonatra Ring X1 and can provide insights into overall health and well-being helping detect signs of stress or changes in circulation.",
    },
   ];

  export const ringX1Data = {
    data: ringX1Features,
    heading: "Bonatra Smart Ring X1 Features",
  }


   export default function handler(
    req: NextApiRequest,
    res: NextApiResponse<featuresData>
  ) {
    res.status(200).json({data: ringX1Features, heading:'Features'})
  }
