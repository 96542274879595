import { BonatraFeatures,BonatraFeatures2 } from "@/pages/api/featuresData";
import styles from "styles/styles.json";
import { AiFillCheckCircle } from "react-icons/ai";
import { UnorderedList, ListItem } from "@chakra-ui/react";




export const FeaturesCard = ({
    id,
    title,
    description1,
    description2,
    description3,

  }: BonatraFeatures) => {

    
    return (
      <div >
        <div className="p-2 h-68" key={id}>
          <div className={`${styles.flexRow} justify-start items-center gap-2`}>
            <AiFillCheckCircle size="1.2rem" className="text-primary" />
            <h4 className="text-[1.5rem] font-bold">{title}</h4>
          </div>
          {/* <p 
            className="text-[1rem] pt-4 text-left"
            dangerouslySetInnerHTML={{ __html: description?.toString() ?? '' }}
          ></p> */}
           <UnorderedList>
  <ListItem className="py-2">{description1}</ListItem>
  <ListItem className="py-2">{description2}</ListItem>
  <ListItem className="py-2">{description3}</ListItem>
</UnorderedList>
  
         
        </div>
        {/* <hr className="h-px my-8 bg-black-200 border-0 dark:bg-black opacity-40 md:dark:bg-black-100" /> */}
      </div>
  
  
    );
  };


  export const FeaturesCard2 = ({
    id,
    description1,
    description2,
    description3,
  }: BonatraFeatures) => {

    return (
      <div >
        <div className="p-2" key={id}>
          <div className={`${styles.flexRow} justify-start items-baseline gap-4`}>
            <AiFillCheckCircle size="1.5rem" className="text-primary" />
            <p>{description1}</p>
            <p>{description2}</p>
            <p>{description3}</p>
            {/* <p 
              className="text-[1.3rem] pt-2 text-left w-[80%]"
              dangerouslySetInnerHTML={{ __html: description?.toString() ?? '' }}
            ></p> */}
          </div>
          <hr className="h-px my-8 bg-black-200 border-0 dark:bg-black opacity-40 md:dark:bg-black-100" />
        </div>
      </div>
  
  
    );
  };
  