import { BonatraFeatures } from "@/pages/api/featuresData";
import styles from "styles/styles.json";
import { AiFillCheckCircle } from "react-icons/ai";


export const RingFeaturesCard = ({
    id,
    title,
    description1,
  }: BonatraFeatures) => {
    return (
      <div >
        <div className="p-4" key={id}>
          <div className={`${styles.flexRow} justify-start items-center gap-2`}>
            <AiFillCheckCircle size="1.2rem" className="text-primary" />
            <h4 className="text-[1.5rem] font-bold">{title}</h4>
          </div>
          <p className="text-[1rem] pt-4 text-left">{description1}</p>
          {/* <hr className="h-px my-8 bg-red-200 border-0 dark:bg-white md:dark:bg-gray-100" /> */}
        </div>
      </div>
  
  
    );
  };
  